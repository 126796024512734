import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { hasAnyParams } from '../src/utils/auth0Params'
import { navigationSelectors } from '../src/store/navigation/navigation.selectors'

const stripLocalization = (destination: string) => {
  const availableLocalisations = useSelector(navigationSelectors.selectAvailableLocalisations)

  const locale = availableLocalisations.find((l) => destination.startsWith(`/${l}`))
  if (locale) return destination.replace(`/${locale}`, '')

  return destination
}

export const useRedirectToCurrentLocalization = () => {
  const router = useRouter()
  let destination = stripLocalization(router.asPath)
  if (hasAnyParams(destination)) destination = '/'

  const currentLocalisation = useSelector(navigationSelectors.selectCurrentLocalisation)

  useEffect(() => {
    router.replace(`/${currentLocalisation}${destination}`)
  }, [router, destination, currentLocalisation])
}

export const useRedirectToLocalized404 = () => {
  const router = useRouter()
  const currentLocalisation = useSelector(navigationSelectors.selectCurrentLocalisation)

  useEffect(() => {
    router.replace(`/${currentLocalisation}/404`)
  }, [router, currentLocalisation])
}
